<template>
  <tr class="etat-vaccinal">
    <td>
        <div>{{ maladie.libelle }} </div>
        <small v-if="vaccin.optional">(Optionel)</small>
    </td>
    
    <td>
      <ul>
        <li
          v-for="(v, i) in availablesVaccinations"
          :key="i"
        >
        <span v-if="isInPrimoVaccination">Is Primo</span>
          <router-link :to="{name: 'detail_vaccination', params: {uid: v.uid}}">
            [{{ vaccin.valence }}] - {{ v.createdAt|showTime }}
          </router-link>
        </li>
      </ul>
    </td>
    <td>
      {{ nextVaccination|systemReadableTime }} 
    </td>
    <td>  
      <div
        class="circle"
        :class="state"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
export default {
    props: {
        vaccin: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            selectedAnimal: 'identification/selectedAnimal',
            animaux: 'identification/animaux',
            vaccinations: 'sanitaire/vaccinations',
            maladies: 'sanitaire/maladies'
        }),
        maladie(){
            let m = this.maladies.find(item => item.uid === this.vaccin.maladie)
            if(m) return m
            return null
        },
        animal(){
            let a = this.animaux.find(item => item.uid === this.selectedAnimal)
            if(a) return a
            return null
        },
        primoVaccination(){
            if(this.vaccin.primo !== null){
                let primo = this.vaccin.primo.split(",")
                primo.sort((a,b) => a-b )
                return primo
            }
            return []
            
        },
        isInPrimoVaccination(){
            if(this.animal !== null){
                let diff = moment().diff(moment(this.animal.dateNaissance), 'days')
                for(let i = 0; i < this.primoVaccination.length; i++){
                    if(this.primoVaccination[i] > diff) return true
                }
                return false
            }
            return false
        },
        nextVaccination(){
            const lastVaccination = this.vaccinations.filter(item => item.animal === this.selectedAnimal && item.vaccin === this.vaccin.uid)
            .reduce((latest, current) => { return (!latest || new Date(current.dateRappel) > new Date(latest.dateRappel)) ? current : latest; }, null)
            if (lastVaccination !== null && lastVaccination !== undefined) return lastVaccination.dateRappel
            if(this.isInPrimoVaccination) {
                let diff = moment().diff(moment(this.animal.dateNaissance), 'days')
                if(diff > parseInt(this.primoVaccination[0])) return moment()
                return moment().add(this.primoVaccination[0], 'days')
            }
            return null
        },
        state(){
            let diff = moment().diff(moment(this.nextVaccination), 'days')
            if(diff < 0) return 'success'
            return 'danger'
        },
        availablesVaccinations(){
            return [...this.vaccinations].filter(item => item.animal === this.selectedAnimal && item.vaccin === this.vaccin.uid)
        }
    }
}
</script>

<style lang="scss" scoped>
.etat-vaccinal{
    .circle {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        opacity: .25;
        border: solid 1px rgba($color: #000000, $alpha: 0.25);
    }
    .danger {
        background-color: #b50404;
        -webkit-animation: up-right 1s infinite;
        -moz-animation: up-right 1s infinite;
        -o-animation: up-right 1s infinite;
        animation: up-right 1s infinite;
    }
    .success{
        background-color: #1C6100;
        opacity: 1;
    }
} 
@keyframes up-right {
    0% { 
        transform: scale(1);
        opacity: .25
    }
    50% {
        transform: scale (1, 5);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: .25;
    }
}  
</style>